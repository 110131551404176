<template>
  <div class="box">
    <div style="padding: 1rem;">
      <div class="backdiv">
        <div style="padding-top: 1rem;font-size: 1.2rem;"> 立即支付 </div>
        <div v-if="totlePotin != 0" style="padding-top: 1rem;margin-bottom: 0.5rem; ">
          <span style="color: #D33B2E; font-size: 1.5rem;"> {{ totlePotin }}</span>
          <span style="color: #D33B2E;font-size: 1rem;">点</span>
          <!-- <span style="color: #D33B2E; font-size: 1.5rem;"> {{sale_price }}点</span> -->
          <!-- <span style="font-size: 0.85rem;">(需支付{{ totlePotin }}点)</span> -->
        </div>
        <div style="margin-bottom: 0.5rem;">
          <span style="font-size: 0.85rem;font-weight: 600;"> 剩余支付时间 {{ countTime }} </span>
        </div>
        <div style="margin-bottom: 0.5rem;">
          <span style="font-size: 0.85rem;color: #999;"> 请尽快支付，超时后系统将取消订单 </span>
        </div>
      </div>
      <div class="backdiv" style="margin-top: 0.5rem;">
        <div style="padding: 1rem;font-size: 1rem;"> 选择支付方式 </div>
        <div class="itemback" @click="onCheckWeiX">
          <div
            style="align-items: center;text-align: center;display: flex;flex-direction: row; justify-content: space-between;">
            <van-image style="height: 1.5rem; width: 1.5rem;" :src="require('../assets/images/wechat.png')"></van-image>
            <div style="margin-left: 0.8rem;font-size: 1rem;"> 微信支付</div>
          </div>
          <van-checkbox checked-color="#D33B2E" style="margin-left: 0.2rem;margin-right: 0.2rem;" v-model="weichecked"
            @change="weiChange">
          </van-checkbox>
        </div>
        <div class="itemback" @click="showpop">
          <div
            style="align-items: center;text-align: center;display: flex;flex-direction: row; justify-content: space-between;">
            <van-icon name="card" size="1.5rem" color="#D33B2E" />
            <div style="margin-left: 0.5rem;font-size: 1rem;"> 福利卡支付</div>
          </div>
          <van-checkbox checked-color="#D33B2E" style="margin-left: 0.5rem;margin-right: 0.2rem;" v-model="cardChecked"
            @change="carChange">
          </van-checkbox>
        </div>
      </div>
      <div class="addCard">
        <div style="margin: 0 0 0.5rem 1.2rem;color: #D33B2E;font-size: 0.8rem;">
          {{ tag }}
        </div>
        <div
          style="display: flex;flex-direction: row; justify-content: space-between; padding: 0.5rem 0; background-color: white;">
          <div style="display: flex;flex-direction: column;margin-left: 1.2rem;">
            <div style="padding-bottom: 0.5rem;"><span>福利卡抵扣</span><span style="color: #D33B2E;"> {{ payPoint }}点</span>
            </div>
            <span>还需支付<span style="color: #D33B2E; "> {{ weiPrice }}元</span></span>
          </div>
          <div class="buy" style="margin:0 1rem " @click="payOrderInfo">
            去支付
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="show" title="选择福利卡">
      <div style="max-height: 25rem;">
        <div style="text-align: center;width: 100%;padding-bottom: 1rem;color: #D59A54;">
          <span style="font-size: 0.7rem;">提示：使用多张卡支付订单时，系统依据选卡顺序对卡进行点数消耗</span>
        </div>
        <div v-for="(item, index) in payList" :key="index"
          :class="wxPoints > 0 && wxPoints != item.wxPoints ? 'noitemback' : 'itemback'" @click="onCheck(item)">
          <div style="display: flex;flex-direction: row;align-items: center; flex: 1;">
            <van-icon name="card" size="1.5rem" color="#D33B2E" />
            <div style="margin-left: 0.8rem; text-align: left; width: 100%;">
              <div style="display: flex; flex-direction: row;justify-content: space-between;">
                <span style="font-size: 0.8rem;">{{ item.name }} </span>
                <div style="display: flex;flex-direction: row;justify-content: space-between;">
                  <span style="font-size: 0.8rem;">余额:{{ item.cardPoint }}点 </span>
                </div>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-between; margin-top: 0.2rem; ">
                <span style="font-size: 0.8rem;">卡号:{{ item.cardNo }}
                </span>
              </div>
            </div>
          </div>
          <div style=" display: flex;flex-direction: row;min-width: 1.5rem;">
            <van-checkbox checked-color="#D33B2E" style="margin-left: 0.5rem;" :value="item.check">
            </van-checkbox>
          </div>
        </div>
        <div>
        </div>
        <div>
          <div style="margin: 0 0 0.5rem 1.2rem;color: #D33B2E;font-size: 0.8rem;">
            {{ tag }}
          </div>
          <div
            style="display: flex;flex-direction: row; justify-content: space-between; padding: 0.5rem 0; background-color: white;">
            <div style="display: flex;flex-direction: column;margin-left: 1.2rem;">
              <div style="padding-bottom: 0.5rem;"><span>福利卡抵扣</span><span style="color: #D33B2E;"> {{ payPoint
                  }}点</span>
              </div>
              <span>还需支付<span style="color: #D33B2E; "> {{ weiPrice }}元</span></span>
            </div>
            <div class="buy" style="margin:0rem 1rem " @click="payOrderInfo">
              去支付
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <PutPassWord v-if="showPass" @closed="closeds" @success="PWDSuccess"></PutPassWord>
  </div>
</template>
<script>
import PutPassWord from '../components/cashier/PutPassWord.vue'
import { Decimal } from 'decimal.js'

import { Toast } from "vant";
export default {
  name: 'Cashier',
  data() {
    return {
      orderId: '',
      sale_price: '',
      weiPrice: 0,
      totlePotin: 0,
      weichecked: false,
      cardChecked: false,
      show: false,
      showPass: false,
      tag: '温馨提示：请选择支付方式',
      ticketType: 1,  //1 通兑票  2  1.3比例
      repairDiffPrice: '1',  //0 不支持补差价 1 普通补差价 2风险补差价
      wxPoints: '',
      payList: [
      ],
      checked: [],
      cardId: '',
      cityCode: '',
      filmId: '',
      payPoint: 0,
      paytype: '',//zy-shop自营
      cakaOrder: null,
      price: '',
      seconds: 600, // 10分钟的秒数
      countTime: '',
      orderCreadTime: undefined,
      havaPassword: false,
      userId: '',

    }
  },
  components: { PutPassWord },
  methods: {

    closeds() {
      this.showPass = false

    },
    //支付密码验证成功
    PWDSuccess() {
      this.showPass = false
      if (this.paytype == 'zy-shop' || this.paytype == 'sf-shop' || this.paytype == 'hw') { //自营商品
        this.payProductInfo()
      } else
        this.payInfo()
    },
    countDown() { // 计算倒计时
      let m = parseInt(this.seconds / 60 % 60);
      m = m < 10 ? "0" + m : m
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s
      this.countTime = m + ':' + s
    },
    Time() { // 定时器
      if (this.orderCreadTime) {
        let newDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        //计算相差多少天，参数可传：day 、second、minute
        let dateRange = this.$moment(newDate).diff(this.$moment(this.orderCreadTime), 'seconds')
        this.seconds -= dateRange
      }
      if (this.seconds < 0) {
        this.countTime = '已超时'
        return
      }
      var timer = setInterval(() => {
        this.seconds -= 1
        this.countDown()
        if (this.seconds == 1) {
          this.countTime = '已超时'
          clearInterval(timer);
        }
      }, 1000)
    },
    onCheckWeiX() {
      this.weichecked = true
    },
    onCheck(val) {
      if (!val.check && this.payList.filter(item => item.check).length >= 5) {
        Toast('该笔订单最多允许五张卡支付');
        return
      }
      if (!val.check && this.repairDiffPrice == '0') {
        this.weichecked = false
      }
      if (this.wxPoints > 0 && this.wxPoints != val.wxPoints) {
        Toast('所选卡不能合并支付');
        return
      }
      this.updatePayListChecked(val);
      this.getWXPrice(this.payPoint)
    },

    /**
     * 变更paylist: check状态
     */
    updatePayListChecked(record) {

      const payList = this.payList.map(item => {
        const { id, check, timestamp } = item;
        return {
          ...item,
          check: record.id == id ? !check : check,
          timestamp: record.id == id ? new Date().getTime() : timestamp
        }
      })
      this.payList = payList;
      this.computePayPoint(payList)
    },
    /**
     * 计算支付点数
     */
    computePayPoint(payList) {
      const checkedCardItem = payList.find(item => item.check);
      this.payPoint = 0
      this.payList.forEach((item, index) => {
        if (item.check) {
          this.payPoint = this.payPoint + parseFloat(item.cardPoint)
        }
      });
      this.cardChecked = checkedCardItem ? true : false
      this.wxPoints = checkedCardItem ? checkedCardItem.wxPoints : 0;
      this.payPoint = this.payPoint > this.totlePotin ? this.totlePotin : this.payPoint;
      this.payPoint = Math.round(this.payPoint * 100) / 100
    },
    getWXPrice(totle) {
      if (totle == 0) {
        this.weiPrice = parseFloat(this.sale_price)
      } else
        if (this.wxPoints) {
          this.weiPrice = Math.round(((this.totlePotin - totle) * this.wxPoints) * 100) / 100
        } else
          this.weiPrice = Math.round(((this.totlePotin - totle)) * 100) / 100
      if (this.weiPrice <= 0) {
        this.weichecked = false
      }
    },
    queryPayBenefitCard() {
      let entrCode = localStorage.getItem("entrCode");
      let seatList = JSON.parse(localStorage.getItem("seatList"))
      let maxSeatPrice = 0
      seatList.forEach(element => {
        if (parseFloat(element.price) > maxSeatPrice) {
          maxSeatPrice = parseFloat(element.price)
        }
      });

      let form = {
        cardId: this.cardId,
        cityCode: this.cityCode,
        filmId: this.filmId,
        entrCode: entrCode,
        maxSeatPrice: maxSeatPrice,
        seatNum: seatList.length
      }

      this.$api
        .queryPayBenefitCard(form)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
            return
          }
          this.payList.push(...res.data)
          if (this.payList.length > 0) {
            this.paymentRatio = parseFloat(this.payList[0].paymentRatio)
            this.ticketType = parseFloat(this.payList[0].ticketType)
            this.repairDiffPrice = this.payList[0].repairDiffPrice
            this.totlePotin = Math.round(this.weiPrice * 100) / 100
            if (this.repairDiffPrice == '0' || this.repairDiffPrice == '1') {
              this.tag = '温馨提示：请选择支付方式不支持退改签'
            }

            this.payList.forEach(item => {
              item.timestamp = new Date().getTime();
            });

          }
        })
        .catch((err) => {
          Toast(err.msg);
        });
    },
    initProductIds() {
      let ids = ''
      if (this.cakaOrder?.productSubOrderList) {
        this.cakaOrder?.productSubOrderList?.forEach(element => {
          ids = ids + element.productId + ','
        });
      } else if (this.cakaOrder?.orderSortInfoList) {
        this.cakaOrder?.orderSortInfoList?.forEach(element => {
          ids = ids + element.productId + ','
        });
      }
      this.orderPayBenefitCard(ids)
    },
    orderPayBenefitCard(productIds) {
      this.$api
        .queryPayBenefitCardNew(productIds, this.cardId)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
            return
          }
          this.payList.push(...res.data)
          if (this.payList.length > 0) {
            this.paymentRatio = parseFloat(this.payList[0].paymentRatio)
            this.ticketType = parseFloat(this.payList[0].ticketType)
            this.repairDiffPrice = this.payList[0].repairDiffPrice
            this.totlePotin = Math.round(this.weiPrice * 100) / 100
            this.payList.forEach(item => {
              item.timestamp = new Date().getTime();
            });
          }
        })
        .catch((err) => {
          Toast(err.msg);
        });
    },

    carChange() {
      if (this.cardChecked) {
        const checkedCardItem = this.payList.find(item => item.check);
        this.cardChecked = checkedCardItem ? true : false
        if (this.cardChecked) {
          this.weichecked = false
        }
      } else {
        this.payList.forEach((item, index) => {
          item.check = false
          this.$set(this.payList, index, item);  //使用this.$set更新
        });
        this.payPoint = 0
        this.wxPoints = 0
        this.getWXPrice(this.payPoint)
      }
    },
    weiChange() {
      if (this.weichecked) {
        this.cardChecked = false
      }
    },
    showpop() {
      // this.cardChecked = true
      if (this.cardChecked) {
        this.cardChecked = false
        this.payList.forEach((item, index) => {
          item.check = false
          item.timestamp = new Date().getTime();
          this.$set(this.payList, index, item);  //使用this.$set更新
        });
      } else {
        this.cardChecked = true
        this.show = true
      }

    },
    directWeChat(params) {
      let this_ = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": params.appId,     //公众号ID，由商户传入     
        "timeStamp": params.timeStamp,        //时间戳，自1970年以来的秒数     
        "nonceStr": params.nonceStr,//随机串     
        "package": params.package,
        "signType": params.signType,       //微信签名方式：     
        "paySign": params.paySign, //微信签名 
      },
        function (res) {
          Toast.clear();
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            Toast.success('支付成功');
            this_.goHome();
          } else {
            this.$router.push({
              path: "/AllOrder",
              query: {
                active: 1
              },
            });
          }
        });
    },
    init(params) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.directWeChat(params), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.directWeChat(params))
          document.attachEvent('onWeixinJSBridgeReady', this.directWeChat(params))
        }
      } else {
        this.directWeChat(params)
      }
    },
    getCardIds() {
      let cardIds = ''
      let timeList = []
      this.payList.forEach((item) => {
        if (item.check) {
          // cardIds = cardIds + item.id + ','
          timeList.push(item)
        }
      });
      if (timeList.length > 0) {
        timeList = timeList.sort((a, b) => a.timestamp - b.timestamp)
        timeList.forEach(element => {
          cardIds = cardIds + element.id + ','
        });
      }
      return cardIds
    },
    getUnSelectCardIds() {
      let uncardIds = []
      this.payList.forEach((item) => {
        if (!item.check) {
          uncardIds.push(...item.id)
        }
      });
      return uncardIds
    },
    payOrderInfo() {
      if (this.ticketType == 1) {
        // 不允许补差价
        if (this.repairDiffPrice == '0') {
          //未选择微信
          if (!this.weichecked) {
            if (this.weiPrice > 0) {
              this.showdialog2()
              return
            }
          }
        } else if (this.repairDiffPrice == '1') { //普通补差价
          //未选择微信
          let getcards = this.getUnSelectCardIds()
          if (!this.weichecked) {
            if (this.weiPrice > 0) {
              if (getcards.length > 0) {
                this.showdialog4()
                return
              } else {
                this.showdialog3()
                return
              }

            }
          }
        }
      }
      if (this.weiPrice > 0) {
        if (!this.weichecked) {
          this.showdialog1()
          return
        }
      }
      this.toPay()
    },
    toPay() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      if (this.weiPrice > 0) {
        if (this.paytype == 'zy-shop' || this.paytype == 'sf-shop' || this.paytype == 'hw') { 
          this.payProductInfo()
        } else
          this.payInfo()
      } else {
        this.$api
          .pwdCheck()
          .then((res) => {
            if (res.data) {
              this.showPass = true
            } else {
              Toast.clear();
              this.$Dialog.confirm({
                title: "提示",
                message: "您还未设置支付密码，暂时不能支付",
                confirmButtonText: '去设置',
                cancelButtonText: '取消'
              })
                .then(() => {
                  this.payPwd = ''
                  this.$router.push({
                    path: "/SetPassWord",
                  });
                })
                .catch(() => {
                  // on cancel
                });
            }

          })
          .catch((err) => {
          });
      }
    },
    showdialog1() {
      this.$Dialog.confirm({
        title: "提示",
        message: "已选产品余额不足，差额部分将使用微信联合支付，是否继续？",
      })
        .then(() => {
          this.toPay()
        })
        .catch(() => {
          // on cancel
        });
    },
    showdialog2() {
      this.$Dialog.confirm({
        title: "提示",
        message: "当前产品不支持微信联合支付，已选票数不足，请重新选择。",
        confirmButtonText: '放弃'
      })
        .then(() => {

        })
        .catch(() => {
          // on cancel
        });
    },
    showdialog3() {
      this.$Dialog.confirm({
        title: "提示",
        message: "已选点数不足，差额部分将使用微信联合支付，是否继续？",
        confirmButtonText: '继续兑换',
        cancelButtonText: '放弃'
      })
        .then(() => {
        })
        .catch(() => {
          // on cancel
        });
    },
    showdialog4() {
      this.$Dialog.confirm({
        title: "提示",
        message: "已选点数不足，请重新选择",
        confirmButtonText: '放弃',
        cancelButtonText: '取消'
      })
        .then(() => {
        })
        .catch(() => {
          // on cancel
        });
    },
    payProductInfo() {
      if (this.countTime == '已超时') {
        this.$Toast('该订单已超时，请重新下单')
        return
      }
      this.cakaOrder.cardId = this.cardId
      this.cakaOrder.cardIds = this.getCardIds()
      this.cakaOrder.cardPoints = this.payPoint > this.totlePotin ? this.totlePotin : this.payPoint
      this.cakaOrder.consumptionType = this.weiPrice > 0 ? this.getCardIds().length > 0 ? '2' : '1' : '0'
      this.cakaOrder.orderNo = this.orderId
      this.cakaOrder.tripartiteOrderId = this.orderId
      this.cakaOrder.wxAmount = this.weiPrice > 0 ? this.weiPrice : ''
      this.cakaOrder.price = this.price
      this.$api
        .payProductInfo(this.cakaOrder)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
          } else {
            if (this.cakaOrder.wxAmount == 0) {
              Toast('支付成功');
              this.goHome();
            } else {
              this.weiPay()
            }
          }
          Toast.clear();
        })
        .catch((err) => {
          Toast(err.msg);
          Toast.clear();
        });
      // }
    },
    payInfo() {
      if (this.countTime == '已超时') {
        this.$Toast('该订单已超时，请重新下单')
        return
      }
      let form = {
        cardId: this.cardId,
        cardIds: this.getCardIds(),
        cardPoints: this.payPoint > this.totlePotin ? this.totlePotin : this.payPoint,
        consumptionType: this.weiPrice > 0 ? this.getCardIds().length > 0 ? '2' : '1' : '0',
        tripartiteOrderId: this.orderId,
        wxAmount: this.weiPrice > 0 ? this.weiPrice : '',
        price: this.sale_price
      }
      if (form.cardIds.length == 0) {
        this.weiPay()
      } else {
        this.$api
          .payOrderInfo(form)
          .then((res) => {
            if (res.code == 1) {
              Toast(res.msg);
            } else {
              if (form.wxAmount == 0) {
                Toast('支付成功');
                this.goHome();
              } else {
                this.weiPay()
              }
            }
          })
          .catch((err) => {
            Toast(err.msg);
          });
      }
    },
    weiPay() {
      let mobile = localStorage.getItem('loginphone')
      this.$api
        .confirmWxByMobile(mobile)
        .then((res) => {
          if (res.code == 0) {
            if (res.data) {
              Toast.clear();
              this.$Dialog.confirm({
                title: "提示",
                message: "微信支付需要先授权",
                confirmButtonText: '去授权',
                cancelButtonText: '取消'
              })
                .then(() => {
                  this.$router.push({
                    path: "/Personal",
                  });
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              this.WXPay()
            }
          } else {
            Toast.clear();
          }
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    WXPay() {
      let form = {
        amount: parseFloat(this.weiPrice),
        userId: this.userId,
        channelOrderNo: this.orderId,
        consumptionType: this.weiPrice > 0 ? this.getCardIds().length > 0 ? '2' : '1' : '0'
      }
      this.$api
        .generatePayOrderInfo(form)
        .then((res) => {
          if (res.code == 1) {
            Toast.clear();
            this.$Toast(res.msg);
          } else {
            this.init(res.data)
          }
        })
        .catch((err) => {
          Toast.clear();
          Toast(err.msg);
        });
    },
    goHome() {
      // this.$router.replace({
      //   path: "/",
      // });
      if (this.paytype == 'zy-shop' || this.paytype == 'sf-shop' || this.paytype == 'hw') { //非电影商品
        this.$router.replace({
          path: "/CakaOrderDetail",
          query: {
            orderNo: this.orderId,
            orderType: this.paytype,
          },
        });
      } else {
        this.$router.replace({
          path: "/OrderDetail",
          query: {
            orderId: this.$route.query.orderId2,
            qmmOrderId: this.orderId,
            status: '6',
            orderNo: this.$route.query.orderNo,
          },
        });
      }
    },
    getDetail(orderNo) {

      this.$api
        .queryPayOrderInfo(orderNo)
        .then((res) => {
          if (res.code === 0) {
            this.cakaOrder = res.data
            this.sale_price = this.cakaOrder.originalPrice
            this.weiPrice = parseFloat(this.sale_price)
            this.price = this.cakaOrder.originalPrice
            this.orderId = this.cakaOrder.orderNo
            this.initProductIds()
          }
        })
        .catch((err) => {
          Toast(err.msg);
        });
    }

  },
  mounted() {
    this.userId = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : ''
    this.orderId = this.$route.query.order
    this.cardId = this.$route.query.cardId ? this.$route.query.cardId : localStorage.getItem("cardId");
    this.filmId = localStorage.getItem("film_id");
    this.cityCode = localStorage.getItem("cityCode");
    this.sale_price = localStorage.getItem("sale_price");
    this.cakaOrder = JSON.parse(localStorage.getItem('gooddetail'))
    this.paytype = this.$route.query.type
    if (this.paytype == 'zy-shop' || this.paytype == 'sf-shop') { //非电影商品
      this.sale_price = this.cakaOrder.originalPrice
      this.weiPrice = parseFloat(this.sale_price)
      this.price = this.cakaOrder.originalPrice
      this.orderId = this.cakaOrder.orderNo
      this.initProductIds()
    } else if (this.paytype == 'hw') {
      let orderNo = this.$route.query.orderNo
      this.getDetail(orderNo)
    } else {
      this.weiPrice = parseFloat(this.sale_price)
      this.queryPayBenefitCard()
    }
    this.orderCreadTime = this.$route.query.creatTime
    this.Time()
  },
  beforeRouteLeave(to, from, next) {

    if (to.path === '/SetPassWord' || to.path === '/Personal') {
      console.log('path', to.path, 'from', from.path)
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
      let Vnode = this.$vnode;
      let parentVnode = Vnode && Vnode.parent;
      if (parentVnode && parentVnode.componentInstance && parentVnode.componentInstance.cache) {
        var key = Vnode.key == null ? Vnode.componentOptions.Ctor.cid + (Vnode.componentOptions.tag ? `::${Vnode.componentOptions.tag}` : '') : Vnode.key;
        var cache = parentVnode.componentInstance.cache;
        var keys = parentVnode.componentInstance.keys;
        if (cache[key]) {

          this.$destroy();
          if (keys.length) {
            var index = keys.indexOf(key);
            if (index > -1) {
              keys.splice(index, 1);
            }
          }
          cache[key] = null;
        }
      }
    }
    next()
  },
}

</script>
<style lang="scss" scoped>
.box {
  background-color: #f8f8f8;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.wrapper2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.foot-btn {
  width: 100%;
  margin-top: 2.5rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  border-radius: 1.5rem;
  color: #fff;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  font-size: 1.1rem;
}

.block {
  width: 100%;
  height: 17rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: #fff;
}


.backdiv {
  width: 100%;
  text-align: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  padding-bottom: 0.5rem;
}

.itemback {
  border-radius: 0.5rem;
  border: 1px solid #BBB;
  padding: 1rem;
  margin: 0 1.1rem 0.8rem 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noitemback {
  border-radius: 0.5rem;
  border: 1px solid #DDD;
  padding: 1rem;
  margin: 0 1.1rem 0.8rem 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.addCard {
  position: absolute;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
}

.buy {
  border-radius: 1.5rem;
  height: 2.8rem;
  line-height: 2.8rem;
  width: 6rem;
  font-size: 1.1rem;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  color: #f5f5f5;
  text-align: center;
}

.buyg {
  border-radius: 1.3rem;
  height: 2.8rem;
  line-height: 2.8rem;
  width: 6rem;
  font-size: 1.1rem;
  background-color: #ccc;
  color: #f5f5f5;
  text-align: center;
}
</style>