<template>
  <div>
    <van-popup v-model="show" @closed="closed" round :style="{ minWidth: '85%' }">
      <div
        style="display:flex ;flex-direction: row; height: 4.4rem;line-height: 4.4rem; justify-content: space-between;padding:0 1.1rem;">
        <div style="margin-top: 0.28rem;" @click='closeGoOrder'> <van-icon size="1.2rem" name="cross" color="#666" />
        </div>
        <div style="margin-top: 0.28rem; font-size: 1.16rem;letter-spacing: 0;">请输入支付密码</div>
        <div style="margin-top: 0.28rem; width: 1.2rem;"></div>
      </div>

      <div style="width: 100%;height: 1px;background: rgba(219,219,219,0.60);"></div>
      <van-password-input :class="pasTrue ? '' : 'error'" style="margin-top: 1.7rem;" :value="payPwd" gutter="0.5rem"
        :focused="showKeyboard" @focus="showKeyboard = true" />
      <div
        style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 0.85rem;padding: 0 0.5rem;">
        <div v-if="errorNum < 5"></div>
        <div v-else style="color:#D33B2E;margin-left: 1rem;letter-spacing: 0;">{{ msg }}</div>
        <div v-if="errorNum < 5" @click="forgetPass" style="color:rgba(97, 96, 96, 0.6);">忘记密码？</div>
        <div v-else></div>
      </div>
      <van-button class="btn" @click="pwdContrast" style="font-size: 1rem; margin: 1.7rem 1.1rem 1rem 1.1rem ;">{{ button
      }}</van-button>
    </van-popup>
    <van-number-keyboard v-model="payPwd" :show="showKeyboard" @blur="showKeyboard = false" z-index="9999" />
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: "",
  props: {
  },
  data() {
    return {
      show: true,
      pasTrue: true,
      showKeyboard: false,
      form: {
        userId: '',
        payPwd: '',
      },
      payPwd: '',
      msg: '',
      button: '确认支付',
      errorNum: 0,
    };
  },
  components: {},
  methods: {
    pwdContrast() {
      if (this.payPwd.length < 6) {
        this.$Toast('请正确输入密码');
        return;
      }
      this.form.payPwd = this.payPwd
      if (this.errorNum < 5) {
        this.$api
          .pwdContrast(this.form)
          .then((res) => {
            if (res.code == 0) {
              this.$emit("success");
            } else {
              this.$Toast(res.msg)
            }
            this.pasTrue = res.data.flag
            this.errorNum = res.data.errorNum
            if (!this.pasTrue && this.errorNum > 4) {
              this.button = '重置密码'
            }
            this.msg = res.msg

          })
          .catch((err) => {
          });
      } else {
        this.forgetPass()
      }
    },
    closed() {
      this.$emit("closed", false);
    },

    closeGoOrder() {
      this.$emit("closed", false);
      this.$router.push({
        path: "/AllOrder",
        query: {
          active: 1
        },
      });
    },
    forgetPass() {
      this.form.payPwd = ''
      this.payPwd = ''
      this.$router.push({
        path: "/SetPassWord",
      });
    }
  },
  mounted() {
    // console.log(this.good);
    // 进入页面显示在顶部
    window.scroll(0, 0);
    this.form.userId = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : ''
  },
  computed: {},
  watch: {
    payPwd(value) {
      console.log('payPwd');
      if (value.length > 6) {
        this.payPwd = this.payPwd.slice(0, 6)
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.van-password-input__security li {
  border: 1px #e8e8e8 solid;
  border-radius: 0.5rem;
  height: 2.8rem;
}

.error {
  ::v-deep.van-password-input__security li {
    border: 1px #d33b2e solid;
    border-radius: 0.5rem;
    height: 2.8rem;
  }
}

.img {
  width: 5rem;
}

.tag {
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  border: 1px solid #666;
  color: #666;
  margin: 0.2rem 1rem;
}

.selectTag {
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  margin: 0.2rem 1rem;
  border: 1px solid #d33b2e;
  color: #d33b2e;
}

.btn {
  width: 90%;
  height: 2.8rem;
  text-align: center;
  line-height: 2.8rem;
  border-radius: 2rem;
  color: #fff;
  background-image: linear-gradient(90deg, #ffc782 0%, #d39850 100%);
  font-size: 1rem;
}
</style>